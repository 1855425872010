@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/box-shadow' as *
@use 'src/css/variables/media' as media
@use 'src/css/variables/z-index' as *

.bs-w-popup
  background-color: get-color(bs-w)
  border-radius: $border-radius-base
  box-shadow: $box-shadow-base
  color: get-color(bs-gm)
  display: flex
  inline-size: rem-fn(370)
  margin: 0
  position: absolute
  top: rem-fn(132)
  z-index: $popup-z-index

  p
    margin-block-end: rem-fn(20)

  h2
    font-size: rem-fn(16)
    font-weight: 700
    line-height: line-height(22, 16)
    margin-block-end: rem-fn(10) !important

  &-with-buttons
    flex-flow: column
    inline-size: 100%

    @include media-query.from(media.$md-min)
      flex-flow: row
      inline-size: rem-fn(440)

  &:before
    content: ''
    border-left: rem-fn(12) solid transparent
    border-right: rem-fn(12) solid transparent
    border-bottom: rem-fn(12) solid get-color(bs-w)
    height: 0
    left: calc(50% - #{rem-fn(12)})
    width: 0
    position: absolute
    top: rem-fn(-10)
    z-index: $popup-z-index - 1

.bs-w-p-review
  padding: rem-fn(30)

.bs-w-p-buttons
  display: flex

  @include media-query.from(media.$md-min)
    flex-flow: column
    justify-content: space-evenly

.bs-w-p-b-email,
.bs-w-p-b-invite
  align-items: center
  block-size: rem-fn(60)
  display: flex
  justify-content: center
  inline-size: 50%

  svg
    inline-size: rem-fn(24)
    margin-block-end: 0

  @include media-query.from(media.$md-min)
    block-size: rem-fn(120)
    inline-size: rem-fn(80)

.bs-w-p-b-email
  background-color: get-color(bs-g)
  border-end-start-radius: $border-radius-base

  @include media-query.from(media.$md-min)
    border-end-start-radius: 0
    border-start-end-radius: $border-radius-base

.bs-w-p-b-invite
  background-color: get-color(bs-nb)
  border-end-end-radius: $border-radius-base
