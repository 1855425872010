@use '~booksprout-app/src/css/functions/rem-fn' as *

.bs-w-rating
  align-items: center
  column-gap: rem-fn(20)
  display: flex
  font-weight: 600

  .q-rating
    img
      // TODO: task "Replace reviewers temp image with canvas"
      margin-block-end: 0 !important

      &:not(:last-child)
        margin-inline-end: rem-fn(8)
