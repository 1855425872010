@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '../css/functions/get-color' as *

@keyframes flip
  from
    transform: rotateY(180deg)

  to
    transform: rotateY(-180deg)

.bs-w-spinner
  align-items: center
  background-color: get-color(bs-g)
  block-size: rem-fn(70)
  border-radius: 50%
  display: flex
  justify-content: center
  inline-size: rem-fn(70)

  img
    animation: flip 3s linear infinite
    perspective: rem-fn(1000)
